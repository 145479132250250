import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const ApiTags = Object.freeze({
  Accounts: 'Accounts',
  Applicants: 'Applicants',
  Assessments: 'Assessments',
  AssessmentTypes: 'AssessmentTypes',
  ApplicantComments: 'ApplicantComments',
  Cycles: 'Cycles',
  Files: 'Files',
  HiringAssessmentTypes: 'HiringAssessmentTypes',
  Phases: 'Phases',
  PublishedAssessments: 'PublishedAssessments',
  SkillbuilderAssessmentTypes: 'SkillbuilderAssessmentTypes',
  Batteries: 'Batteries',
  BatterySubmissions: 'BatterySubmissions',
  Integrations: 'Integrations',
  Organizations: 'Organizations',
  Users: 'Users',
  HiringAnalytics: 'HiringAnalytics',
  EmailTemplates: 'EmailTemplates',
  TextTemplates: 'TextTemplates',
  SkillbuilderCycles: 'SkillbuilderCycles',
  HiringTemplates: 'HiringTemplates',
  HiringViews: 'HiringViews',
  HiringViewsDetails: 'HiringViewsDetails',
  HiringViewsCycles: 'HiringViewsCycles',
  CyclesBoostInvitableApplicants: 'CyclesBoostInvitableApplicants',
  CycleBoostInvitableApplicant: 'CycleBoostInvitableApplicant',
  SBTrainingUsers: 'SBTrainingUsers',
  Triggers: 'Triggers',
  ModuleInfos: 'ModuleInfos',
  Statuses: 'Statuses',
  Regions: 'Regions',
  Degrees: 'Degrees',
  SBCandidates: 'SBCandidates',
  SemanticSearchApplicants: 'SemanticSearchApplicants',
  Jobs: 'Jobs',
  ExpertiseCategory: 'ExpertiseCategory',
  CycleOpenInvites: 'CycleOpenInvites',
  AssessmentInvites: 'AssessmentInvites',
  CyclesPhases: 'CyclesPhases',
  CyclesStages: 'CyclesStages',
  CyclePasses: 'CyclePasses',
  StageProgresses: 'StageProgresses',
  EmailHistory: 'EmailHistory',
  InviteConfigTemplate: 'InviteConfigTemplate'
})

export const ApiAbstractIds = Object.freeze({
  List: 'LIST',
  PartialList: 'PARTIAL-LIST',
  Export: 'EXPORT'
})

export const ApiMethods = Object.freeze({
  Get: 'GET',
  Post: 'POST',
  Delete: 'DELETE',
  Patch: 'PATCH',
  Put: 'PUT'
})

export const ApiResponseTypes = Object.freeze({
  ArrayBuffer: 'arraybuffer'
})

export const apiSlice = createApi({
  reducerPath: 'apiReducer',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1/' }),
  tagTypes: Object.keys(ApiTags),
  endpoints: () => ({})
})

export function providesList (result, tag) {
  return result?.items
    ? [
        ...result.items.map(({ id }) => ({ type: tag, id: id })),
        { type: tag, id: ApiAbstractIds.List }
      ]
    : [{ type: tag, id: ApiAbstractIds.List }]
}
