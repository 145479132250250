import React from 'react'
import { Center, Loader } from '@mantine/core'

/**
 * Renders a loading animation. Useful to let know the user that something is being processed.
 * @returns {JSX.Element}
 */
function Loading () {
  return (
    <Center><Loader/></Center>
  )
}

export default React.memo(Loading)
