import dayjs from 'dayjs'

/**
 * This function allows to format a date using dayjs.
 * @param {?string} date A date as string.
 * @param {string} [format] Optional format. Defaults to "MM-DD-YYYY".
 * @returns {string} The date in the given format.
 */
export function format (date, format = 'MM-DD-YYYY') {
  return dayjs(date).format(format)
}

/**
 * This function checks if a date is within a distance with the current date.
 * @param {?string} date A date as string.
 * @param {QUnitType|OpUnitType} units The units to calculate the distance with. Available units: https://day.js.org/docs/en/display/difference#list-of-all-available-units
 * @param {number} expected The number of units to compare with.
 * @param {boolean} [truncate] Optional. If the difference should be truncated before comparing with expected.
 * @returns {boolean} Returns true if date is within the expected number of units with the current date. False otherwise..
 */
export function within (date, units, expected, truncate = false) {
  return dayjs().diff(date, units, truncate) <= expected
}
